<template>
  <Main>
    <sdPageHeader :title="i18n.t('menuItems.solar-feed')"> </sdPageHeader>
    <SupportTopWrap>
      <div class="sDash-support-container">
        <a-row align="middle">
          <a-col :lg="16" :sm="14" :xs="24">
            <div class="sDash_support-content">
              <p>{{ i18n.t('solar_feed.page.description') }}</p>
              <div class="sDash-support-buttons">
                <p v-for="button of miniButtons" :key="button.icon">
                  <sdFeatherIcons :color="button.color" :type="button.icon" :size="20" />
                  <span>
                    {{ button.text }}
                  </span>
                </p>
              </div>
              <p>
                <b>{{ i18n.t('solar_feed.page.access') }}</b>
              </p>
              <a v-bind:href="info_link" target="_blank">
                <sdButton class="btn-ticket" size="large" outlined type="primary">
                  {{ i18n.t('solar_feed.page.more') }}
                </sdButton>
              </a>
              <!--<sdButton class="btn-ticket" size="large" outlined type="primary" raised @click="isVisible = !isVisible">
                {{ i18n.t('solar_feed.page.get') }}
              </sdButton>-->
            </div>
          </a-col>
          <a-col :lg="8" :sm="10" :xs="24">
            <div class="sDash_support-img">
              <img :src="require(`../../static/img/banner/solar.png`)" alt="" />
            </div>
          </a-col>
        </a-row>
      </div>
    </SupportTopWrap>
    <GetAccessModal v-model:isVisible="isVisible" />
  </Main>
</template>

<script>
import { computed, reactive, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import GetAccessModal from '@/components/modals/GetAccessModal';
import { Main } from '../styled';
import { SupportTopWrap } from './style';

export default {
  name: 'Faqs',
  components: {
    Main,
    SupportTopWrap,
    GetAccessModal,
  },
  setup() {
    const i18n = reactive(useI18n());

    const isVisible = ref(false);
    const miniButtons = computed(() => [
      {
        color: '#FF4D4F',
        text: i18n.t('solar_feed.page.button_first'),
        icon: 'underline',
      },
      {
        color: '#FF69A5',
        text: i18n.t('solar_feed.page.button_second'),
        icon: 'percent',
      },
      {
        color: '#FA8B0C',
        text: i18n.t('solar_feed.page.button_third'),
        icon: 'sun',
      },
      {
        color: '#20C997',
        text: i18n.t('solar_feed.page.button_fourth'),
        icon: 'sunset',
      },
      {
        color: '#4347D9',
        text: i18n.t('solar_feed.page.button_fifth'),
        icon: 'moon',
      },
      {
        color: '#272B41',
        text: i18n.t('solar_feed.page.button_sixth'),
        icon: 'plus',
      },
    ]);
    const info_link =
      i18n.locale == 'ru'
        ? process.env.VUE_APP_WEBSITE_URL + '/ru/' + process.env.VUE_APP_SOLAR_FEED_LINK
        : process.env.VUE_APP_WEBSITE_URL + '/' + process.env.VUE_APP_SOLAR_FEED_LINK;

    return { i18n, miniButtons, isVisible, info_link };
  },
};
</script>
