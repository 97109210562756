<template>
  <sdModal centered :title="i18n.t('solar_feed.form.get')" :visible="isVisible" :onCancel="closeModal" :width="350">
    <a-form :model="formData" @finish="getAccess" name="access" ref="access">
      <a-form-item ref="email" name="email" class="input__wrapper" :label="i18n.t('solar_feed.form.email')">
        <a-input v-model:value="formData.email" size="large" type="email" />
      </a-form-item>
      <a-form-item ref="purpose" name="purpose" class="input__wrapper" :label="i18n.t('solar_feed.form.purpose')">
        <a-textarea v-model:value="formData.purpose" size="large" type="purpose" showCount :maxlength="200" />
      </a-form-item>
      <div class="action__wrapper">
        <sdButton class="action__button" size="large" :disabled="isLoading" type="success" htmlType="submit" raised>
          {{ i18n.t('solar_feed.form.get', 2) }}
        </sdButton>
        <sdButton class="action__button" size="large" type="danger" @click="closeModal" raised>
          {{ i18n.t('start.cancel') }}
        </sdButton>
      </div>
    </a-form>
  </sdModal>
</template>

<script>
import { reactive, ref } from '@vue/reactivity';
import { computed, watchEffect } from 'vue';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

const OpenLootBox = {
  name: 'OpenLootBox',

  props: {
    isVisible: Boolean,
  },

  setup(props, { emit }) {
    const { state } = useStore();
    const i18n = reactive(useI18n());
    const formData = ref({ email: '', purpose: '' });
    const isLoading = ref(false);

    const profile = computed(() => state.profile.data);

    const closeModal = () => {
      emit('update:isVisible', false);
    };

    const getAccess = () => {
      console.log(formData.value);
    };

    watchEffect(() => {
      formData.value.email = profile.value?.email;
    });

    return {
      i18n,
      formData,
      isLoading,
      closeModal,
      getAccess,
    };
  },
};
export default OpenLootBox;
</script>
<style lang="scss" scoped>
.action__wrapper {
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}
</style>
